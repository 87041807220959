import "@/styles/globals.css";
import type { AppProps } from "next/app";
import Head from "next/head";

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>Perps & Options on Bitcoin L2s & EVMs</title>
        <meta
          name="description"
          content="Perps and Options aggregator on Bitcoin L2s, Ethereum L2s and Avalanche"
        />
      </Head>
      <Component {...pageProps} />
    </>
  );
}
